export interface MaddashConfigurationOptions {
	selectAllMax: number;
}

export class UserAccess {
	hha: boolean;
	hos: boolean;

	constructor(private _userAccess: string) {
		this.hha = true; // _userAccess.HHA;
		this.hos = true; // _userAccess.HOS;
	}
	getPortals(): string[] {
		return ['hha', 'hos', 'snf'];
		/*
		return Object.keys(this._userAccess).map(portal =>
			portal.toLocaleLowerCase()
		) as string[];
		*/
	}
}

export class MaddashUser {
	// tslint:disable-next-line: variable-name
	user_id: number;
	username: string;
	name: string;
	email: string;
	status: string;
	title: string;
	customerId: number;
	failedLoginAttemptCount: number;
	expirationDate: string;
	lastPasswordChangeDate: string;
	userAccess: UserAccess;

	constructor(obj: any) {
		this.user_id = obj.id;
		this.username = obj.username;
		this.name = obj.name;
		this.email = obj.email;
		this.status = obj.status;
		this.title = obj.title;
		this.customerId = obj.customerId;
		this.failedLoginAttemptCount = obj.failedLoginAttemptCount;
		this.expirationDate = obj.expirationDate;
		this.lastPasswordChangeDate = obj.lastPasswordChangeDate;
		this.userAccess = new UserAccess(obj.userAccess);
	}
}

export class CurrentUserResponse {
	user: MaddashUser;
	configOptions: MaddashConfigurationOptions;
	portals: string[];

	constructor(response: any) {
		this.user = new MaddashUser(response); // MaddashUser(response.user);
		this.configOptions = response.configOptions;
		this.portals = ['HHA', 'HOS', 'SNF']; // this.user.userAccess.getPortals();
	}
}
