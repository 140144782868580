import { Injectable, OnDestroy } from '@angular/core';
import { Observable, timer, Subject } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpRequest, ResponseContentType } from '../api/http-request';
import { AuthenticationService } from '../shared/providers/authentication.service';
import { ICategory, IAgreement, IRequest } from '../shared/shared.interfaces';

export const pollToken = {
  tic: 'tic',
  requests: 'requests',
  providers: 'providers',
  agreements: 'agreements',
  userstats: 'userstats',
  categories: 'categories',
  geoposition: 'geoposition',
  reload: 'reload',
};

const EVENT_INTERVAL = 20000; // timer event frequency: 20 seconds

@Injectable({
  providedIn: 'root',
})
export class PollService implements OnDestroy {
  public ticPipe: Observable<any>;
  // public requestPipe = new Subject();
  // public providerPipe = new Subject();
  // public userStatsPipe = new Subject();
  public pollPipe = new Subject(); // can receive all types of polls
  public poll: any;
  public requestData: Array<IRequest>;
  public providerData: Array<IRequest>;
  public agreementData: Array<IAgreement>;
  public userStatsData: any;
  public categories: Array<ICategory>;

  private stopPolling = new Subject();

  private urlRequest = environment.apiUrl + '/request';
  private urlProvider = environment.apiUrl + '/request/provider';
  private urlAgreement = environment.apiUrl + '/agreement/requester';
  private urlUserStats = environment.apiUrl + '/user_stats';
  private urlCategories = environment.apiUrl + '/category';
  private urlLocation = environment.apiUrl + '/location';
  private urlSocket = environment.apiUrl + '/socket/';
  private urlPushNotification = environment.pushNotificationUrl;

  private urlParams = {};

  requestList: Array<IRequest> = [];
  notificationLog = [];
  myEventSource: any = null;

  public geoPosition = null;
  public geoPositionTime: Date = null;
  public geoPositionFrequency = 30; // seconds
  public noBrowserSupportForCoord = false;

  public geoSendLocationFrequency = 900; // Send location every 15 min even if I have not moved
  public geoSendLocationDistance = 0.1; // Send location if I have moved more than .1 miles
  public geoSentTime: Date = null; // Indicates the last time I sent my location.
  public geoSentPosition = null; // Indicates the last position I sent to the server

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpRequest
  ) {
    // Create a timer pipe that fires an event every EVENT_INTERVAL seconds
    this.ticPipe = timer(1, EVENT_INTERVAL).pipe(
      share(),
      takeUntil(this.stopPolling)
    );

    // Subscribe to the timer and poll each data set that needs to be refreshed regularly.
    this.ticPipe.subscribe(() => {
      this.pollNow(pollToken.tic);
    });

    this.setupPushNotifications();
  }

  ngOnDestroy() {
    this.stopPolling.next();
    this.myEventSource = null;
  }

  public pollDelay(target: string, delay = 250) {
    setTimeout(() => {
      this.pollNow(target);
    }, delay);
  }

  public pollNow(target: string) {
    switch (target) {
      case pollToken.tic:
        this.getRequest();
        this.getProvider();
        this.getAgreement();
        this.getUserStats();
        this.getCoordinates(false); // get if needed (time has lapsed)
        if (!this.myEventSource) {
          this.setupPushNotifications();
        } // Attempt to connect if not connected
        break;
      case pollToken.requests:
        this.getRequest();
        break;
      case pollToken.providers:
        this.getProvider();
        break;
      case pollToken.agreements:
        this.getAgreement();
        break;
      case pollToken.userstats:
        this.getUserStats();
        break;
      case pollToken.categories:
        // only if needed
        if (!this.categories) {
          this.getCategories();
        }
        break;
      case pollToken.geoposition:
        this.getCoordinates(true); // force get
        break;
      case pollToken.reload:
        this.getCategories();
        this.getRequest();
        this.getProvider();
        this.getAgreement();
        this.getUserStats();
        break;
    }
  }

  private getRequest() {
    this.http
      .get(this.urlRequest, this.urlParams, {
        responseType: ResponseContentType.json,
      })
      .subscribe(
        (data) => {
          this.requestData = data;
          const sendPoll = { requests: data }; // matches pollToken.requests
          this.pollPipe.next(sendPoll);
        },
        (error) => {
          console.log('error: poll.service.getRequest(): ', error);
        }
      );
  }

  private getProvider() {
    this.http
      .get(this.urlProvider, this.urlParams, {
        responseType: ResponseContentType.json,
      })
      .subscribe(
        (data) => {
          this.providerData = data;
          const sendPoll = { providers: data }; // matches pollToken.providers
          this.pollPipe.next(sendPoll);
        },
        (error) => {
          console.log('error: poll.service.getProvider(): ', error);
        }
      );
  }

  /**
   * Get the agreements
   *
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  private getAgreement() {
    this.http
      .get(
        this.urlAgreement,
        { count: 50 },
        { responseType: ResponseContentType.json }
      )
      .subscribe(
        (data) => {
          this.agreementData = data;
          const sendPoll = { agreements: data }; // matches pollToken.providers
          this.pollPipe.next(sendPoll);
        },
        (error) => {
          console.log('error: poll.service.getAgreement(): ', error);
        }
      );
  }

  private getUserStats() {
    this.http
      .get(this.urlUserStats, this.urlParams, {
        responseType: ResponseContentType.json,
      })
      .subscribe(
        (data) => {
          this.userStatsData = data;
          const sendPoll = { userstats: data }; // matches pollToken.userstats
          this.pollPipe.next(sendPoll);
        },
        (error) => {
          console.log('error: poll.service.getUserStats(): ', error);
        }
      );
  }

  private getCategories() {
    this.http
      .get(this.urlCategories, this.urlParams, {
        responseType: ResponseContentType.json,
      })
      .subscribe(
        (data) => {
          this.categories = data;
          const sendPoll = { categories: data }; // matches pollToken.categories
          this.pollPipe.next(sendPoll);
        },
        (error) => {
          console.log('error: poll.service.getCategories(): ', error);
        }
      );
  }

  private getCoordinates(force: boolean) {
    let doGet = false;
    if (force) {
      doGet = true;
    } else {
      if (this.noBrowserSupportForCoord) {
        return;
      } // no need to check again
      // Check if we do not yet have coordinate info
      if (this.geoPosition == null || this.geoPositionTime == null) {
        doGet = true;
      } else {
        // Check if the time has been longer than our poll frequency
        const nowCheck = new Date(); // current date-time
        const elapsed =
          (nowCheck.getTime() - this.geoPositionTime.getTime()) / 1000; // seconds
        if (elapsed > this.geoPositionFrequency) {
          doGet = true;
        }
      }
    }
    // Get Coordinates if indicated we should do so.
    if (doGet) {
      if (!navigator.geolocation) {
        this.noBrowserSupportForCoord = true;
      } else {
        this.noBrowserSupportForCoord = false;

        navigator.geolocation.getCurrentPosition(
          // GET GEOPOSITION
          (position) => {
            this.geoPosition = position;
            this.geoPositionTime = new Date();

            // Push this down the event chain
            const sendPoll = { geoposition: position }; // matches pollToken.geoposition
            this.pollPipe.next(sendPoll);
            this.SendGeoIfNeeded();
          },
          // ERROR GEOPOSTION
          () => {
            // alert(err);
          },
          // GEOPOSITION REQUEST SETTINGS
          { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
        );
      }
    }
  }

  private SendGeoIfNeeded() {
    let sendIt = true;

    // debugger;
    // There is nothing to do if we don't have a location...
    if (
      !(
        this.geoPosition &&
        this.geoPosition.coords &&
        this.geoPosition.coords.longitude
      )
    ) {
      return;
    }
    const lon1 = this.geoPosition.coords.longitude;
    const lat1 = this.geoPosition.coords.latitude;

    // Check if we do not have a geoSentTime/geoSentPosition indicating we need to do this for the first time...
    if (this.geoSentTime && this.geoSentPosition) {
      // Check if the time interval has passed indicating it is time to send the geoLocation again
      const nowCheck = new Date(); // current date-time
      const elapsed = (nowCheck.getTime() - this.geoSentTime.getTime()) / 1000; // seconds
      if (elapsed < this.geoSendLocationFrequency) {
        // Check if the distance has changed by more than geoSendLocationDistance miles
        // Use distance formula for THE GREAT CIRCLE
        // 3959.0 * ACOS(
        //    COS( RADIANS(lat1) ) * COS( RADIANS(lat2) ) * COS( RADIANS(lon2) - RADIANS(lon1) )
        //    + SIN( RADIANS(lat1) ) * SIN( RADIANS(lat2) ) )
        if (
          this.geoPosition &&
          this.geoPosition.coords &&
          this.geoPosition.coords.longitude &&
          this.geoSentPosition &&
          this.geoSentPosition.coords &&
          this.geoSentPosition.coords.longitude
        ) {
          const lon2 = this.geoSentPosition.coords.longitude;
          const lat2 = this.geoSentPosition.coords.latitude;
          const rad = Math.PI / 180;
          const geoDist =
            3959.0 *
            Math.acos(
              Math.cos(lat1 * rad) *
                Math.cos(lat2 * rad) *
                Math.cos((lon2 - lon1) * rad) +
                Math.sin(lat1 * rad) * Math.sin(lat2 * rad)
            );
          if (geoDist < this.geoSendLocationDistance) {
            sendIt = false;
          }
        } // end if...
      } // end if (elapsed < this.geoSendLocationFrequency) {
    } // end if (this.geoSentTime && this.geoSentPosition)

    // We alrady checked to make sure we have a geoPosition
    if (sendIt) {
      this.geoSentPosition = this.geoPosition;
      this.geoSentTime = new Date(); // current date-time

      // Send HTTP GET request
      const locParams = {
        lon: lon1,
        lat: lat1,
      };

      this.http
        .put(this.urlLocation, locParams, {
          responseType: ResponseContentType.json,
        })
        .subscribe(
          () => {
            // this.requestList = data ;
          },
          (error) => {
            console.log('error: poll.service.SendGeoIfNeeded(): ', error);
          }
        );
    } //  end if (sendIt)
  } // end function

  private setupPushNotifications() {
    // Make sure the user is logged in before connecting to the push service
    if (!this.authenticationService.isLoggedIn()) {
      this.myEventSource = null;
      return; // no error - we can set this up later after login
    }

    this.myEventSource = new EventSource(this.urlPushNotification);

    // Handle connection events
    const myThis = this;
    this.myEventSource.addEventListener('open', () => {
      console.log('DEBUGGER: The push connection has been openned');
      myThis.addNotification(
        '############ OPEN EVENT ' + new Date().toString()
      );
    });

    this.myEventSource.addEventListener('close', () => {
      // This never seems to happen
      console.log('DEBUGGER: The push connection has been closed');
      myThis.addNotification(
        '------------ CLOSE EVENT ' + new Date().toString()
      );
    });

    // If you get an error message, post it to the screen.
    this.myEventSource.addEventListener('error', (event: any) => {
      console.log('Error ', event);
      myThis.addNotification('Connection timeout or break');
    });

    // --- Handle messages ---
    this.myEventSource.addEventListener(
      'message',
      (event: { data: string }) => {
        const data = JSON.parse(event.data);
        const verb = data.verb;
        const message = data.message;
        if (verb === 'error') {
          console.log('Error ', event);
          myThis.addNotification('Error:' + message);
        }

        // If you get an identity message, the server wants to know your identity.  You get a passcode to send back.
        if (verb === 'identity') {
          console.log('Identity ', message);
          const connection_id = message.connection_id;
          const passcode = message.passcode;
          // Send the passcode back to the API through the socket route
          myThis.socket(connection_id, passcode);
        }

        if (verb === 'verified') {
          console.log('user_id:' + message.user_id);
          myThis.addNotification('Verified user ID:' + message.user_id);
        }

        if (verb === 'ping') {
          console.log('Ping received');
          myThis.addNotification('Connection alive - ping');
        }

        // verb=request (poll for request updates from other users)
        if (verb === 'request') {
          console.log('Request received');
          this.poll.pollNow(pollToken.providers); // update providers list
          myThis.addNotification('Message - Request received');
        }

        // verb=offer (poll for offer updates on MyRequests)
        if (verb === 'offer') {
          console.log('Offer received');
          this.poll.pollNow(pollToken.requests); // update requests list
          myThis.addNotification('Message - Offer received');
        }

        // verb=message (direct message ie. text message)
        // verb=tracking (includes location info - for mobile tracking)
        if (verb === 'message' || verb === 'tracking') {
          myThis.addNotification(verb + ':' + message);
        }
      }
    ); // end add event listener
  }

  private addNotification(msg: string) {
    this.notificationLog.unshift(msg);
    while (this.notificationLog.length > 50) {
      this.notificationLog.pop();
    }
    const sendPoll = { notifications: this.notificationLog }; // matches pollToken.categories
    this.pollPipe.next(sendPoll);
  }
  // tslint:disable-next-line: variable-name
  private socket(connection_id: string, passcode: any) {
    const urlParams = { passcode };
    this.http.post(this.urlSocket + connection_id, urlParams).subscribe(
      () => {
        console.log('error: poll.service.socket(): socket message sent.');
      },
      (error) => {
        console.log('error: poll.service.socket(): ', error);
      }
    );
  }
}
