import { IStatusId } from './shared.interfaces';

export const ERROR_MESSAGES = {
	default: 'Sorry, an unexpected error occurred while attempting to contact the server. Please try again.'
};

export const PERMISSIONS = {
	ADMIN_ACCESS: 'adminView7',
	NPI_GROUPS_CREATE: 'npigroups.create',
	NPI_GROUPS_COMPARISON_CREATE: 'npigroups.comparison.create',
	NPI_GROUPS_COMPARISON_ADD: 'npigroups.comparison.npi.add',
	NPI_GROUPS_COMPARISON_DELETE: 'npigroups.comparison.npi.delete',
	NPI_GROUPS_DELETE: 'npigroups.delete',
	NPI_GROUPS_LIST: 'npigroups.list',
	FAVORITES_LIST: 'favorites.list',
	FAVORITES_DELETE: 'favorites.delete',
	FAVORITES_CREATE: 'favorites.create',
	TARGETS_CREATE: 'targets.create',
	TARGETS_DELETE: 'targets.delete',
	TARGETS_EDIT: 'targets.update',
	TARGETS_LIST: 'targets.list',
	CHART_CART_CREATE: 'chartcart.create',
	CHART_CART_LIST: 'chartcart.list',
	CHART_CART_DELETE: 'chartcart.delete',
	PAGE_DASHBOARD_SHOW: 'page.dashboard.show',
	PAGE_EXPLORE_SHOW: 'page.explore.show',
	PAGE_ANALYZE_SHOW: 'page.analyze.show',
	PAGE_SHARE_SHOW: 'page.share.show',
	SHARE_PUBLIC_OPTION: 'datakit.create.public',
	SHARE_CUSTOM_OPTION: 'datakit.create.share',
	DATAKIT_CREATE: 'datakit.create',
	DATAKIT_GET: 'datakit.get',
	DATAKIT_CREATE_PMV: 'datakit.create.pmv',
	DATAKIT_UPDATE: 'datakit.update',
	DATAKIT_LIST: 'datakit.list'
};


export interface RouteAttr {
	route: string;
	label: string;
	icon: string;
}

export const ROUTE_CONSTANTS = {
	returnUrlLabel: 'returnUrl',
	newRouteParam: 'new',
	idRouteParam: 'id',
	login: {
		route: 'login',
		label: 'Login',
		icon: ''
	},
	ping: {
		route: 'ping',
		label: 'Ping',
		icon: ''
	},
	verify: {
		route: 'verify',
		label: 'Verify',
		icon: ''
	},
	register: {
		route: 'register',
		label: 'Register',
		icon: ''
	},
	logout: {
		route: 'logout',
		label: 'Logout',
		icon: ''
	},
	loginmenu: {
		route: 'loginmenu',
		label: 'Login Menu',
		icon: 'fas fa-th'
	},
	home: {
		route: '',
		label: 'Home',
		icon: 'fas fa-th'
	},
	query: {
		route: 'query',
		label: 'Query',
		icon: 'fas fa-th'
	},
	stats: {
		route: 'stats',
		label: 'Stats',
		icon: 'fas fa-th'
	},

	nonmobile: {
		route: 'nonmobile',
		label: 'NonMobile',
		icon: 'fas fa-th'
	},
	track: {
		route: 'track',
		label: 'Track',
		icon: 'fas fa-th'
	},
	help: {
		route: 'help',
		label: 'Help',
		icon: 'fas fa-th'
	},
	proto: {
		route: 'proto',
		label: 'Prototype',
		icon: 'fas fa-th'
	}
};


export enum DISPLAY_VALUE {
	lessThanEleven = '<11',
	dash = '-',
	insufficient = 'ins',
}


export enum PROVIDER_TYPE {
	physician = 'physician',
	facility = 'facility',
	hospice = 'hospice',
	homehealth = 'homehealth',
	skillednursing = 'skillednursing',
	noProvider = 'noProvider'
}

export enum TARGET_TYPE {
	UA = 'UA',
	CA = 'CA',
	UU = 'UU',
	US = 'US'
}

export const TARGET_TYPE_OPTIONS = [
	{
		display: `Underutilizing (${TARGET_TYPE.UU})`,
		value: TARGET_TYPE.UU
	},
	{
		display: `Unaffiliated (${TARGET_TYPE.UA})`,
		value: TARGET_TYPE.UA
	},
	{
		display: `Competitor Affiliated (${TARGET_TYPE.CA})`,
		value: TARGET_TYPE.CA
	},
	{
		display: `User Selected (${TARGET_TYPE.US})`,
		value: TARGET_TYPE.US
	},

];

export const TARGET_TYPES = Object.values(TARGET_TYPE);

export enum CLASSIFICATION_TYPE {
	NONE = 'none',
	LOW = 'low',
	MEDIUM = 'medium',
	HIGH = 'high'
}

export const CLASSIFICATION_TYPES = Object.values(CLASSIFICATION_TYPE);

export enum DASHBOARD_TYPE {
	manager = 'manager',
	rep = 'rep'
}



export const SPECIAL_DATA_VALUES = [DISPLAY_VALUE.lessThanEleven, DISPLAY_VALUE.dash, DISPLAY_VALUE.insufficient];

export const ProviderTypeArray = [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility, PROVIDER_TYPE.hospice, PROVIDER_TYPE.homehealth, PROVIDER_TYPE.skillednursing];


export const LOCAL_STORAGE_KEY = {
	JWT: 'maddash_access_token',
	ANALYZE_PAGE: 'maddash_analyze',
};

export interface JWT_DECODE {
	userNo: number;
	uID: string;
	username: string;
	customerID: number;
	isManager: string;
	email: string;
}

export enum RAW_VALUE {
	negativeOne = '-1',
	negativeTwo = '-2',
	negativeThree = '-3',
	negativeFour = '-4',
	zero = '0'
}

export enum PORTAL_TYPE {
	HOS = 'hos',
	HHA = 'hha',
	CAREPATH = 'carepath',
	ADMIN = 'admin'
}

export interface ProviderTypeConfig {
	enum?: PROVIDER_TYPE;
	routeString?: string;
	label: string;
	labelSingular?: string;
	icon?: string;
	colorClass?: string;
	bgClass?: string;
}



type ProviderTypeConfigKey = PROVIDER_TYPE.physician | PROVIDER_TYPE.facility | PROVIDER_TYPE.homehealth | PROVIDER_TYPE.hospice | PROVIDER_TYPE.noProvider;
export const ProviderTypeConfigs: { [key in ProviderTypeConfigKey]: ProviderTypeConfig } = {
	physician: {
		enum: PROVIDER_TYPE.physician,
		routeString: PROVIDER_TYPE.physician,
		label: 'Physicians',
		labelSingular: 'Physician',
		icon: 'fas fa-user-md',
		colorClass: 'text-physician',
		bgClass: 'bg-physician'
	},
	facility: {
		enum: PROVIDER_TYPE.facility,
		routeString: PROVIDER_TYPE.facility,
		label: 'Facilities',
		labelSingular: 'Facility',
		icon: 'fas fa-hospital',
		colorClass: 'text-facility',
		bgClass: 'bg-facility'
	},
	homehealth: {
		enum: PROVIDER_TYPE.homehealth,
		routeString: PROVIDER_TYPE.homehealth,
		label: 'Home Health Agencies',
		labelSingular: 'Home Health Agency',
		icon: 'fas fa-home',
		colorClass: 'text-agency',
		bgClass: 'bg-agency'
	},
	hospice: {
		enum: PROVIDER_TYPE.hospice,
		routeString: PROVIDER_TYPE.hospice,
		label: 'Hospices',
		labelSingular: 'Hospice',
		icon: 'fas fa-hospital-symbol',
		colorClass: 'text-agency',
		bgClass: 'bg-agency'
	},
	noProvider: {
		enum: PROVIDER_TYPE.noProvider,
		label: 'None'
	},
};

export enum NPI_GROUP_TYPE {
	myAgency = 1,
	competitor = 2,
	comparison = 3
}

export interface NpiGroupTypeConfig {
	enum: NPI_GROUP_TYPE;
	label: string;
}


type NpiGroupTypeConfigKey = 'myAgency' | 'competitor' | 'comparison';
export const NpiGroupTypeConfigs: { [key in NpiGroupTypeConfigKey]: NpiGroupTypeConfig } = {
	myAgency: {
		enum: NPI_GROUP_TYPE.myAgency,
		label: 'My Agency'
	},
	competitor: {
		enum: NPI_GROUP_TYPE.competitor,
		label: 'Competitor'
	},
	comparison: {
		enum: NPI_GROUP_TYPE.comparison,
		label: 'Comparison'
	}
};


export enum SHARE_LEVELS {
	PRIVATE = 1,
	COMPANY = 2,
	CUSTOM = 3
}

export const AGENCY_PROVIDER_TYPES = [PROVIDER_TYPE.homehealth, PROVIDER_TYPE.hospice];


export const FORMAT_FUNCTION_TARGET_TYPE = 'targetTypeTransform';
export const FORMAT_FUNCTIONS = [FORMAT_FUNCTION_TARGET_TYPE];

export const SHARE_LEVEL_OPTIONS = [
	{ value: SHARE_LEVELS.PRIVATE, label: 'Private' },
	{ value: SHARE_LEVELS.COMPANY, label: 'My Organization', permission: PERMISSIONS.SHARE_PUBLIC_OPTION },
	{ value: SHARE_LEVELS.CUSTOM, label: 'Custom List', permission: PERMISSIONS.SHARE_CUSTOM_OPTION }
];

export enum TemplateType {
	None = 'none',
	PMV = 'pmv'
}
