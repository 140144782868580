import { Component, ViewChild, TemplateRef, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequest, ResponseContentType } from '../../api/http-request';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PollService, pollToken } from 'src/app/poll/poll.service';

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'makerequest-modal',
	templateUrl: './makerequest.component.html',
	styleUrls: ['./makerequest.component.scss']
})
export class MakeRequestComponent {
	// tslint:disable-next-line: variable-name
	@ViewChild('modal_req', { static: false }) modal_req: TemplateRef<any>;
	@Input() categoriesList: any;
	@Input() geoPosition: any;
	myForm: any = {};
	SubmitOfferStatus;
	modalReference;

	constructor(private http: HttpRequest, private modalService: NgbModal, private poll: PollService) { }

	// tslint:disable-next-line: variable-name
	showDialog(offer_initialize: { expiration: string; }) {
		if (offer_initialize) {
			this.myForm = { ...offer_initialize };
		}

		// Set GeoPosition Coordinates
		if (this.geoPosition && this.geoPosition.coords) {
			this.myForm.lat = this.geoPosition.coords.latitude;
			this.myForm.lon = this.geoPosition.coords.longitude;
		}

		const view = this.modal_req; // .createEmbeddedView(null);
		this.modalReference = this.modalService.open(view, {
			backdrop: 'static',
			size: 'lg',
			keyboard: false,
			centered: true
		});
	}

	closeDialog() {
		if (this.modalReference) {
			this.modalReference.close();
		}
	}

	submitOffer() {
		const urlRequest = environment.apiUrl + '/request';

		this.http
			.post(urlRequest, this.myForm, {
				responseType: ResponseContentType.json
			})
			.subscribe(
				data => {
					this.SubmitOfferStatus = data;
					this.poll.pollDelay(pollToken.requests); // refresh the visible list
					this.closeDialog();
				},
				error => {
					console.log('error:', error);
					alert('failed to make request');
				}
			);
	}
}
