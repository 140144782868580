import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserApi } from '../../api/user.api';
import { CurrentUserResponse } from '../models/user';
import { ApplicationCacheService } from './application-cache.service';
import { AuthenticationService } from './authentication.service';
import { ROUTE_CONSTANTS } from '../shared.constants';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private authService: AuthenticationService,
		private appCache: ApplicationCacheService,
		private userApi: UserApi,
		private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (this.authService.token) {

			if (!this.authService.isExpired) {

				// We have a valid token - not expired
				return true; // FUTURE: TODO DEBUGGER - remove this line

				if (this.appCache.user) {
					return true;
				}

				// Haven't retrieved the current user yet, so do that now
				return this.authService.getCurrentUser()
					.then((response: CurrentUserResponse) => {
						const { user, configOptions } = response;
						if (!user) {
							// Something went wrong
							return this.onAuthError();
						}

						return true;
					})
					.catch(() => {
						return this.onAuthError();
					});
			} else {
				// We have an EXPIRED JWT
				return this.onAuthError(ROUTE_CONSTANTS.login.route); // Route to register page
			} // end else
		} // end if

		return this.onAuthError();
	}

	private onAuthError(newRoute?) {
		this.authService.clearToken();
		this.router.navigate([newRoute || ROUTE_CONSTANTS.login.route]);
		return false;
	}

}
