import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ComponentWithSubscription } from '../../shared/component-with-subscriptions';
import { LoginResponse } from '../../shared/models/authentication';
import { ApplicationCacheService } from '../../shared/providers/application-cache.service';
import { AuthenticationService } from '../../shared/providers/authentication.service';
import { UserFeedbackService } from '../../shared/providers/user-feedback.service';

@Component({
  selector: 'help-tab',
  templateUrl: './help-tab.component.html',
  styleUrls: ['./help-tab.component.scss']
})
export class HelpTabComponent extends ComponentWithSubscription implements OnInit {


  constructor(private activatedRoute: ActivatedRoute, private applicationCacheService: ApplicationCacheService,
              private authenticationService: AuthenticationService, private userFeedbackService: UserFeedbackService) {
    super();
  }

  ngOnInit() {

  }

  runMobileReport() {
    alert('Run mobile report');
  }

  runFullReport() {
    alert('Run full report');
  }


}
