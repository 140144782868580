import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ComponentWithSubscription } from '../../shared/component-with-subscriptions';
import { AuthenticationService } from '../../shared/providers/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpRequest, ResponseContentType } from '../../api/http-request';
import { PollService } from 'src/app/poll/poll.service';
import { Subscription } from 'rxjs';
import { MakeRequestComponent } from 'src/app/modal/makerequest/makerequest.component';
import { HelperService } from 'src/app/services/helper.service';
import { MakeOfferComponent } from 'src/app/modal/makeoffer/makeoffer.component';
import { ViewOfferComponent } from 'src/app/modal/viewoffer/viewoffer.component';
import {
  IStatus,
  IRequest,
  IOffer,
  IStatusId,
  ICategory,
  IAgreement,
} from 'src/app/shared/shared.interfaces';
import * as toastr from 'toastr';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'home-tab',
  templateUrl: './home-tab.component.html',
  styleUrls: ['./home-tab.component.scss'],
})
export class HomeTabComponent
  extends ComponentWithSubscription
  implements OnInit, OnDestroy
{
  userStatsData: any;
  userStatsPipe: Subscription;
  requestList: IRequest[];
  categoriesList: Array<ICategory> | null;
  requestProviderList: IRequest[];
  requestAgreementList: Array<IAgreement>;
  geoPosition: Position;
  geoPositionTime: Date;
  requestListOffers: {
    [k: string]: {
      requestId: number;
      show: boolean;
      myOffer: boolean;
      data: Array<IOffer>;
    };
  } = {};

  @ViewChild('makerequest1', { static: false })
  makerequestmodal: MakeRequestComponent;
  @ViewChild('makeoffer1', { static: false })
  makeoffermodal: MakeOfferComponent;
  @ViewChild('viewoffermodal', { static: false })
  viewoffermodal: ViewOfferComponent;

  constructor(
    private authenticationService: AuthenticationService,
    private poll: PollService,
    private http: HttpRequest,
    private helper: HelperService
  ) {
    super();
  }

  ngOnInit() {
    this.geoPosition = this.poll.geoPosition;
    this.geoPositionTime = this.poll.geoPositionTime;

    this.requestList = this.poll.requestData;
    this.categoriesList = this.poll.categories; // get existing categories
    this.requestProviderList = this.poll.providerData; // get existing provider list
    this.requestAgreementList = this.poll.agreementData; // get existing agreement list

    this.userStatsData = this.poll.userStatsData; // get existing request list
    this.userStatsPipe = this.poll.pollPipe.subscribe((data) => {
      const dany: any = data;

      // Requests
      if (dany.requests) {
        this.requestList = dany.requests
          // Remove the expired requests.
          .filter((req: IRequest) => new Date(req.expiration) > new Date())
          // Sort the requests by the expiration date.
          .sort((a: IRequest, b: IRequest) => {
            const dateA = new Date(a.expiration);
            const dateB = new Date(b.expiration);

            if (dateA > dateB) {
              return 1;
            } else {
              return 0;
            }
          });

        this.reloadOffers(false); // other offers to my Requests
      }

      // Providers
      if (dany.providers) {
        this.requestProviderList = dany.providers;
        this.reloadOffers(true); // myOffers to other providers
      }

      // Agreements
      if (dany.agreements) {
        this.requestAgreementList = dany.agreements;
        this.reloadOffers(true); // myOffers to other agreements
      }

      // Categories
      if (dany.categories) {
        this.categoriesList = dany.categories;
      }

      if (dany.userstats) {
        this.setUserStatsData(dany.userstats);
      }

      // geoPosition
      if (dany.geoposition) {
        this.geoPosition = dany.geoposition;
        this.geoPositionTime = new Date();
      }
    });

    // Request categories - only runs if needed
    this.poll.pollNow('categories');
  }

  ngOnDestroy() {
    this.userStatsPipe.unsubscribe();
  }

  /**
   * Check if the user has put out any requests
   *
   * @returns boolean
   * @author Brian K. Kiragu <bkariuk@hotmail.com>
   */
  get hasPutOutRequests(): boolean {
    return this.requestList ? this.requestList.length > 0 : false;
  }

  /**
   * Check if the user has set themeselves as availale to fulfill orders.
   *
   * @returns boolean
   * @author Brian K. Kiragu <bkariuk@hotmail.com>
   */
  get isAvailableToFulfill(): boolean {
    // Check if the list of categories has been instantiated.
    return this.categoriesList
      ? // Check if any category has 'updated' field NOT set to null.
        this.categoriesList.some((category) => category.updated !== null)
      : // Otherwise, unavailable to fulfill.
        false;
  }

  /**
   * Check if there are any requests from other users to fulfill.
   *
   * @returns boolean
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  get hasRequeststoFulfill(): boolean {
    return this.requestProviderList
      ? this.requestProviderList.length > 0
      : false;
  }

  /**
   * Check if a request has been accepted.
   *
   * @param request Request to check against.
   *
   * @returns boolean
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  requestIsAccepted(request: IRequest): boolean {
    return request.status === IStatus.Accepted;
  }

  /**
   * Return an array of offers depending on the request.
   *
   * @param request IRequest Request being checked against.
   *
   * @returns Array<IOffer>
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  getOffersFromRequest(request: IRequest): Array<IOffer> {
    return `r${request.request_id}` in this.requestListOffers
      ? this.requestListOffers[`r${request.request_id}`].data
      : [];
  }

  /**
   * Return an array of agreements depending on the request.
   *
   * @param request IRequest Request being checked against.
   *
   * @returns Array<IAgreement>
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  getAgreementsFromRequest(request: IRequest): Array<IAgreement> {
    return this.requestAgreementList.filter(
      (agreement) => agreement.request_id == request.request_id
    );
  }

  /**
   * Check if the request has any offer(s) that have been accepted.
   *
   * @param requestId number The request ID to check against.
   * @returns boolean
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  checkForAcceptedOffers(requestId: number): boolean {
    // Check if the offers list is empty.
    const offerListIsEmpty =
      Object.entries(this.requestListOffers).length === 0;

    // If empty, there are no accepted offers.
    if (offerListIsEmpty) {
      return false;
    }

    // Check if the offer list has any offers from the request ID.
    const requestHasOffers = `r${requestId}` in this.requestListOffers;

    if (requestHasOffers) {
      // Get all the offers with a matching request ID.
      const offers: Array<IOffer> =
        this.requestListOffers[`r${requestId}`].data;

      // Check if any offers have the status 'Accepted'.
      return offers.some((offer) => offer.status_id === IStatusId.Accepted);
    }

    // Otherwise return false.
    return false;
  }

  setUserStatsData(data: any) {
    this.userStatsData = data;
  }

  refreshAll() {
    this.poll.pollNow('reload');
  }

  makeRequest() {
    const requestInitialize = {
      expiration: this.helper.currentTime(24),
    };
    this.makerequestmodal.showDialog(requestInitialize);
  }

  viewRequest(request: IRequest) {
    console.log(`Viewing request: ${request.request_id}`);
  }

  revokeRequest(request: IRequest) {
    console.log(`Revoking request: ${request.request_id}`);
  }

  viewOffer(offer: IOffer) {
    const user = this.authenticationService.getUserId();
    const tz = new Date().getTimezoneOffset();
    const offerInitialize = {
      providerId: user,
      timezoneOffset: tz,
      ...offer,
    };
    this.viewoffermodal.showDialog(offerInitialize);
  }

  createReqId(requestId: number): string {
    return `r${requestId}`;
  }

  createOfferRecord(requestId: number, myOffer: boolean) {
    // Get the ID.
    const reqId = this.createReqId(requestId);

    // Create the request offer item if it doesn't exists.
    if (!this.requestListOffers.hasOwnProperty(reqId)) {
      this.requestListOffers = {
        ...this.requestListOffers,
        ...{ [reqId]: { requestId, show: false, myOffer, data: [] } },
      };
    }

    // Return the request ID.
    return reqId;
  }

  groupBy(
    xs: { reduce: (arg0: (rv: any, x: any) => any, arg1: {}) => void },
    key: string
  ): any {
    return xs.reduce(
      (rv: { [x: string]: any[] }, x: { [x: string]: string | number }) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      },
      {}
    );
  }

  async getMyOffers() {
    const urlParams = {};
    const urlRequest = environment.apiUrl + '/offer';

    this.http
      .get(urlRequest, urlParams, { responseType: ResponseContentType.json })
      .subscribe(
        (data) => {
          const tmpList: Record<number, Array<IOffer>> = this.groupBy(
            data,
            'request_id'
          );
          for (const [key, offers] of Object.entries(tmpList)) {
            const thisId = this.createOfferRecord(parseInt(key, 10), true);
            this.requestListOffers[thisId].data = offers;
          }
        },
        (error) => {
          console.error(`Error: ${error}`);
        }
      );
  }

  // This function expects offer record to have already been created - createOfferRecord()
  async getRequestOffers(requestId: number) {
    const urlParams = {};
    const reqId = this.createReqId(requestId);
    const urlRequest = environment.apiUrl + '/offer/request/' + requestId;

    this.http
      .get(urlRequest, urlParams, { responseType: ResponseContentType.json })
      .subscribe(
        (data) => {
          this.requestListOffers[reqId].data = data;
        },
        (error) => {
          console.error(`Error: ${error}`);
        }
      );
  }

  async getOffers(requestId: number, myOffer: boolean) {
    if (myOffer) {
      this.getMyOffers(); // myOffers to other providers requests
    } else {
      this.getRequestOffers(requestId); // other offers to my Requests
    }
  }

  async toggleRequestRow(requestId: number, myOffers: boolean = false) {
    const reqId = this.createOfferRecord(requestId, myOffers);

    // We already have the list of offers!
    this.requestListOffers[reqId].show = !this.requestListOffers[reqId].show;

    // We are done if we are closing the row
    // else refresh the list if we are opening it... (ie. continue)
    if (this.requestListOffers[reqId].show) {
      // debugger;
      // need to get/refresh data
      await this.getOffers(requestId, myOffers);
    }
  }

  reloadOffers(myOffers: boolean) {
    for (const key in this.requestListOffers) {
      if (this.requestListOffers.hasOwnProperty(key)) {
        const oneOffer = this.requestListOffers[key];
        if (oneOffer.show && oneOffer.myOffer === myOffers) {
          this.getOffers(oneOffer.requestId, oneOffer.myOffer);
        }
      }
    }
  }

  // Make Offer.
  makeOffer(req: IRequest) {
    const user = this.authenticationService.getUserId();
    const tz = new Date().getTimezoneOffset();
    const offerInit = {
      request_id: req.request_id,
      provider_id: user,
      requester_id: req.requester_id,
      requestTitle: req.request_text,
      expiration: this.helper.currentTime(24),
      fulfill_time: this.helper.currentTime(1),
      timezone_offset: tz,
      coordinates: this.geoPosition.coords,
    };
    this.makeoffermodal.showDialog(offerInit);
  }

  /**
   * Accept an offer by changing its status to accepted, request the agreement
   * and associate it with the request.
   *
   * @param offer IOffer Offer being accepted
   * @returns Promise<void>
   * @author Ted Tyree <tedtyree@gmail.com>
   * @author Brian K. Kiragu (updated) <bkariuki@hotmail.com>
   */
  async acceptOffer(offer: IOffer): Promise<void> {
    const acceptOfferUrl = `${environment.apiUrl}/offer/status/${offer.offer_id}`;
    const agreementUrl = `${environment.apiUrl}/agreement/request/${offer.request_id}`;

    if (offer.status_id === IStatusId.Accepted) {
      toastr.info(`Offer already accepted successfully.`);
      return;
    }

    // Send request to server to accept offer.
    this.http
      .patch(
        acceptOfferUrl,
        { status_id: IStatusId.Accepted },
        { responseType: ResponseContentType.json }
      )
      .subscribe(
        () => toastr.success(`Offer accepted successfully.`),
        () => toastr.error(`Failed to accept offer`)
      );
  }

  rejectOffer(offer: IOffer) {
    const urlRequest = `${environment.apiUrl}/offer/status/${offer.offer_id}`;

    this.http
      .patch(
        urlRequest,
        { status_id: IStatusId.Rejected },
        { responseType: ResponseContentType.json }
      )
      .subscribe(
        () => {
          toastr.error('Offer was rejected');
        },
        () => toastr.error(`Failed to reject offer`)
      );
  }

  /**
   * Mark an agreement as fulfilled.
   *
   * @param agreement Agreement to fulfil.
   * @param status_id Status to update to.
   *
   * @returns void
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  setAgreementStatus(agreement: IAgreement, status: string): void {
    // Get the request URL.
    const urlRequest = `${environment.apiUrl}/agreement/status/${agreement.agreement_id}`;

    // Set the default status ID.
    let status_id = null;

    // Get the correct status ID.
    switch (status) {
      case 'fulfilled':
        status_id = IStatus.Accepted;
        break;

      case 'disputed':
        status_id = IStatus.Rejected;
        break;

      case 'cancelled':
        status_id = IStatus.Withdrawn;
        break;

      default:
        status_id = IStatus.Open;
        break;
    }

    this.http
      .patch(
        urlRequest,
        { status_id },
        { responseType: ResponseContentType.json }
      )
      .subscribe(
        () => toastr.error(`Agreement was ${status}`),
        () => toastr.error(`Agreement was not ${status}`)
      );
  }

  // Format the date to be human readable.
  formatDate(date: string): string {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    }).format(new Date(date));
  }

  // Get offer status class.
  getStatusClass(status: IStatus): string {
    let className = '';

    switch (status) {
      case 'Expired':
        className = 'is-expired';
        break;

      case 'Accepted':
        className = 'is-accepted';
        break;

      case 'Rejected':
        className = 'is-rejected';
        break;

      default:
        className = 'is-open';
        break;
    }

    return className;
  }
}
