import { Component, Input, OnInit } from '@angular/core';
import { IRequest, IOffer } from '../shared/shared.interfaces';

@Component({
	selector: 'app-request-list-item',
	templateUrl: './request-list-item.component.html',
	styleUrls: ['./request-list-item.component.scss']
})
export class RequestListItemComponent implements OnInit {
	// Name of the request type.
	@Input() type: string;

	@Input() isFirst: boolean;
	@Input() isLast: boolean;

	// Name of parent accordion.
	@Input() accordionId: string;

	// The request to dislay.
	@Input() request: IRequest;

	// Function to toggle offers.
	@Input() loadOffersCallback: (args: any) => void;

	// The offers for this particular request.
	@Input() offers?: IOffer[];

	// Get the heading ID.
	get headingId(): string {
		return `heading${this.request.request_id}`;
	}

	// Get the collapsible ID.
	get collapseId(): string {
		return `collapse${this.request.request_id}`;
	}

	// Check if this is a personal request.
	get isPersonal(): boolean {
		return this.type === 'personal';
	}

	// Get the formatted expiration date.
	get expirationDate(): string {
		return new Intl
			.DateTimeFormat('en-US', {
				month: 'short', day: 'numeric', year: 'numeric'
			})
			.format(new Date(this.request.expiration));
	}

	// Check if the request item has any content.
	get hasOffers(): boolean {
		return this.request.offer_count > 0;
	}

	// When user clicks the first button.
	actionOne(): void {
		console.log('Action One');
	}

	// When user clicks the second button.
	actionTwo(): void {
		console.log('Action Two');
	}

	// On Component Init. (Lifecycle hook)
	ngOnInit(): void { }
}
