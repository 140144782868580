import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { VerifyResponse } from '../shared/models/authentication';
import { ApplicationCacheService } from '../shared/providers/application-cache.service';
import { AuthenticationService } from '../shared/providers/authentication.service';
import { UserFeedbackService } from '../shared/providers/user-feedback.service';
import { ROUTE_CONSTANTS } from '../shared/shared.constants';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent extends ComponentWithSubscription implements OnInit {
  @Output() verifyStatus = new EventEmitter();
  securitytoken;
  message; // login error message

  returnUrl: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private applicationCacheService: ApplicationCacheService,
              private authenticationService: AuthenticationService, private userFeedbackService: UserFeedbackService) {
    super();
  }

  ngOnInit() {
    this.returnUrl = this.activatedRoute.snapshot.queryParams[ROUTE_CONSTANTS.returnUrlLabel];
  }

  verify() {
    this.message = '';
    if (!this.securitytoken) {
      this.message = 'Enter security code';
      return;
    }

    this.userFeedbackService.startSpinner();
    this.authenticationService.verify(this.securitytoken)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.userFeedbackService.stopSpinner())
      )
      .subscribe(
        async (obj: VerifyResponse) => {
          // Check if token was succefully sent to user phone/email
          if (!obj.authenticated) {
            this.onInvalidVerify();
            return;
          }

          // Successful: Token Sent: Load user object and navigate to Login Menu
          this.authenticationService.onVerify(obj);
          // await this.authenticationService.getCurrentUser();

          // this.navigationService.navigateToVerify();
          this.router.navigate([ROUTE_CONSTANTS.track.route]);

        },
        x => {
          this.securitytoken = '';
          this.onInvalidVerify();
          this.userFeedbackService.stopSpinner();
        });

       // Temporary Login - DEBUGGER - FUTURE TODO - complete above API login
    // if (this.username == 'demo' && this.password == 'norbert') {
    //       this.authenticationService.backdoor_debugger_login();
    //       this.router.navigate([ROUTE_CONSTANTS.query.route]);
    //    }
  }

  onInvalidVerify() {
    this.message = 'Security code verification failed. Please try again or try requesting a new security token.';
  }
}
