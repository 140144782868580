import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Observable } from 'rxjs';
import { _throw } from 'rxjs/observable/throw';
import { catchError, map } from '../../../node_modules/rxjs/operators';
import { environment } from '../../environments/environment';
import { ERROR_MESSAGES } from '../shared/shared.constants';

export interface MaddashHttpResult {
	success: boolean;
	result: any;
	errorMsg: string;
}

export enum HttpVerb {
	Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Patch = 'PATCH',
	Delete = 'DELETE'
}

export enum ResponseContentType {
	arraybuffer = 'arraybuffer',
	blob = 'blob',
	document = 'document',
	json = 'json',
	text = 'text'
}

export interface HttpOptions {
	skipStringify?: boolean;
	headers?: {
		[name: string]: any
	};
	responseType?: ResponseContentType;
}

interface HttpClientOptions {
	headers?: HttpHeaders | {
		[header: string]: string | string[];
	};
	params?: HttpParams | {
		[param: string]: string | string[];
	};
	reportProgress?: boolean;
	withCredentials?: boolean;
	responseType?: any;
}

@Injectable({
	providedIn: 'root'
})
export class HttpRequest {
	standardRequestHeaders = [
		{ key: 'Cache-Control', value: 'no-cache' },
		// { key: 'Pragma', value: 'no-cache' },
		// { key: 'Expires', value: 'Sat, 01 Jan 2000 00: 00: 00 GMT' },
		{ key: 'Content-Type', value: 'application/json' },
	];

	overrideRequestHeaders = [
		{ key: 'X-HTTP-Method-Override', value: 'GET' },
	];

	constructor(private http: HttpClient) {
		this.handleError = this.handleError.bind(this);
	}

	get(url: string, getParams?: any, options?: HttpOptions): Observable<any> {
		let headers = new HttpHeaders();

		this.standardRequestHeaders
			.forEach((value) => (headers = headers.set(value.key, value.value)));

		let params: HttpParams = new HttpParams();
		for (const key in getParams) {
			if (getParams.hasOwnProperty(key)) {
				params = params.set(key, getParams[key]);
			}
		}

		const junk = { headers, params, withCredentials: true };

		// return this.wrapObservable(this.http.post(url, params, clientOptions));
		return this.http.get(url, junk);
	}

	get_old(url: string, getParams?: any, useHttpOverride?: boolean): Observable<any> {
		if (useHttpOverride) {
			// We want to send this in as a POST request wth a body, but let the server know this is actually a get
			return this.getWithHttpOverride(url, getParams);
		}

		let headers = new HttpHeaders();

		this.standardRequestHeaders.forEach((value) => {
			headers = headers.set(value.key, value.value);
		});


		if (!getParams || isEmpty(getParams)) {
			return this.wrapObservable(this.http.get(url, {
				headers,
				withCredentials: true
			}));
		}

		let params: HttpParams = new HttpParams();
		for (const key in getParams) {
			if (getParams.hasOwnProperty(key)) {
				params = params.set(key, getParams[key]);
			}
		}
		return this.wrapObservable(this.http.get(url, {
			headers,
			params,
			withCredentials: true
		}));
	}

	private getWithHttpOverride(url: string, getParams?: any) {
		let headers = new HttpHeaders();

		const allHeaders = this.standardRequestHeaders.concat(this.overrideRequestHeaders);
		allHeaders.forEach((value) => {
			headers = headers.set(value.key, value.value);
		});

		const clientOptions = this.getClientOptions();
		clientOptions.headers = headers;

		return this.wrapObservable(this.http.post(url, getParams, clientOptions));
	}

	post(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);

		// return this.wrapObservable(this.http.post(url, params, clientOptions));
		return this.http.post(url, params, clientOptions);
	}

	patch(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		// return this.wrapObservable(this.http.patch(url, params, clientOptions));
		return this.http.patch(url, params, clientOptions);
	}

	put(url: string, postParams?: any, options?: HttpOptions): Observable<any> {
		const params = options && options.skipStringify ? postParams : JSON.stringify(postParams);
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.put(url, params, clientOptions));
	}

	delete(url: string, options?: HttpOptions): Observable<any> {
		const clientOptions = this.getClientOptions(options);
		return this.wrapObservable(this.http.delete(url, clientOptions));
	}

	private getClientOptions(options?: HttpOptions): HttpClientOptions {
		const props: HttpClientOptions = {
			withCredentials: false // temp debugger future: was 'true'
		};

		let headers = new HttpHeaders();

		this.standardRequestHeaders.forEach((value) => {
			headers = headers.set(value.key, value.value);
		});

		if (options && options.headers) {
			Object.keys(options.headers).forEach(k => {
				headers = headers.set(k, options.headers[k]);
			});
		}
		props.headers = headers;

		if (options && options.responseType) {
			props.responseType = ResponseContentType[options.responseType];
		}

		return props;
	}

	getUrl(baseUrl: string, route: string) {
		let url = environment.apiUrl;

		if (baseUrl) {
			url += `/${baseUrl}`;
		}

		if (route) {
			url += `/${route}`;
		}

		return url;
	}

	private wrapObservable(observable: Observable<any>): Observable<any> {
		return observable
			.pipe(
				map(x => this.extractData(x)),
				catchError(this.handleError)
			);
	}

	// Currently just a noop
	private extractData(res: MaddashHttpResult) {
		if (!res || !res.success) {
			if (res.errorMsg) {
				console.error(res.errorMsg);
			}
			// FUTURE: TODO
			// throw new Error(ERROR_MESSAGES.default);
		}

		return res.result;
	}

	private handleError(error: HttpErrorResponse) {
		return _throw(error.message);
	}
}
