import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequest, ResponseContentType } from '../../api/http-request';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IStatusId, IOffer } from 'src/app/shared/shared.interfaces';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'viewoffer-modal',
  templateUrl: './viewoffer.component.html',
  styleUrls: ['./viewoffer.component.scss'],
})
export class ViewOfferComponent {
  // tslint:disable-next-line: variable-name
  @ViewChild('modal_1', { static: false }) modal_1: TemplateRef<any>;
  // @Input() offer_initialize: any;

  // tslint:disable-next-line: variable-name
  offer: null | IOffer = null;
  SubmitOfferStatus: any;
  modalReference: any;

  constructor(private http: HttpRequest, private modalService: NgbModal) {}

  /**
   * Get the formatted price in USD.
   *
   * @returns string
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  get formattedPrice(): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'code',
    }).format(this.offer.price);
  }

  get formattedFulfilTime(): string {
    return new Date(this.offer.fulfill_time).toLocaleDateString();
  }

  // tslint:disable-next-line: variable-name
  showDialog(offer_initialize: IOffer) {
    if (offer_initialize) {
      this.offer = { ...offer_initialize };
    }

    const view = this.modal_1; // .createEmbeddedView(null);
    this.modalReference = this.modalService.open(view, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  closeDialog() {
    if (this.modalReference) {
      this.modalReference.close();
    }
  }

  acceptOffer() {
    const urlRequest =
      environment.apiUrl + '/offer/status/' + this.offer.offer_id;

    this.http
      .patch(
        urlRequest,
        {
          status_id: IStatusId.Accepted,
        },
        {
          responseType: ResponseContentType.json,
        }
      )
      .subscribe(
        (data) => {
          this.SubmitOfferStatus = data;
          this.closeDialog();
        },
        (error) => {
          console.log('error:', error);
          alert('failed to accept offer');
        }
      );
  }

  rejectOffer() {
    const urlRequest =
      environment.apiUrl + '/offer/status/' + this.offer.offer_id;

    this.http
      .patch(
        urlRequest,
        { status_id: IStatusId.Rejected },
        { responseType: ResponseContentType.json }
      )
      .subscribe(
        (data) => {
          this.SubmitOfferStatus = data;
          this.closeDialog();
        },
        (error) => {
          console.log('error:', error);
          alert('failed to reject offer');
        }
      );
  }

  counterOffer() {}
}
