import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ComponentWithSubscription } from '../../shared/component-with-subscriptions';
import { LoginResponse } from '../../shared/models/authentication';
import { ApplicationCacheService } from '../../shared/providers/application-cache.service';
import { AuthenticationService } from '../../shared/providers/authentication.service';
import { UserFeedbackService } from '../../shared/providers/user-feedback.service';
import { PollService } from 'src/app/poll/poll.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'stats-tab',
  templateUrl: './stats-tab.component.html',
  styleUrls: ['./stats-tab.component.scss']
})
export class StatsTabComponent extends ComponentWithSubscription implements OnInit {

  userStatsData: any;
  userStatsPipe: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private applicationCacheService: ApplicationCacheService,
              private authenticationService: AuthenticationService,
              private poll: PollService,
              private userFeedbackService: UserFeedbackService) {
    super();
  }

  ngOnInit() {
    this.userStatsData = this.poll.userStatsData; // get existing request list

    this.userStatsPipe = this.poll.pollPipe.subscribe(
      data => {
        const dany:any = data;
        if (dany.userstats) {
          this.setUserStatsData(dany.userstats);
        }
      }
    );
  }

  ngOnDestroy() {
    this.userStatsPipe.unsubscribe();
  }

  setUserStatsData(data) {
    this.userStatsData = data;
  }

  RefreshAll() {
    this.poll.pollNow('reload');
  }

}
