import { Component, Input, OnInit } from '@angular/core';
import { IRequest, IOffer } from '../shared/shared.interfaces';

@Component({
	selector: 'app-request-list',
	templateUrl: './request-list.component.html',
	styleUrls: ['./request-list.component.scss']
})
export class RequestListComponent implements OnInit {
	// Type of request list (personal or open).
	@Input() type = 'personal';

	// Name of request list.
	@Input() name = 'personalRequests';

	// List of requests.
	@Input() requests?: IRequest[];

	// Function to toggle offers.
	@Input() loadOffersCallback: (args: any) => void;

	// List of request offers.
	@Input() offerList?: {
		[id: string]: {
			request_id: number;
			show: boolean;
			myOffer: boolean;
			data: IOffer;
		}
	};

	// Check if any requests have been loaded.
	get hasRequests(): boolean {
		return this.requests
			? this.requests.length > 0
			: false;
	}

	// Get the ID of the accordion.
	get accordionId(): string {
		return `${this.name}Accordion`;
	}

	// Get the offers for this request.
	get offers(): IOffer[] {
		// Get the request IDs.
		const requestIds = this.requests.map(req => `r${req.request_id}`);

		// Filter the offers to only those that are related to the requests.
		return Object
			.entries(this.offerList)
			.filter(([key]) => requestIds.includes(key))
			.map(([, value]) => value.data);
	}

	// On Component Init. (Lifecycle hook)
	ngOnInit(): void { }
}
