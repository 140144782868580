import { ModuleWithProviders, NgModule } from '@angular/core';
import { MaddashComponentsModule } from '../components/components.module';

@NgModule({
  imports: [
    MaddashComponentsModule
  ],
  declarations: [
  ],
  exports: [
    MaddashComponentsModule,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
