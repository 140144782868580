import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ROUTE_CONSTANTS } from './shared/shared.constants';
import { AuthGuard } from './shared/providers/auth.guard';
import { QueryTabComponent } from './main/queryTab/query-tab.component';
import { HomeTabComponent } from './main/homeTab/home-tab.component';
import { StatsTabComponent } from './main/statsTab/stats-tab.component';
import { NonMobileTabComponent } from './main/nonMobileTab/nonMobile-tab.component';
import { TrackTabComponent } from './main/trackTab/track-tab.component';
import { HelpTabComponent } from './main/helpTab/help-tab.component';
import { RegisterComponent } from './register/register.component';
import { PingComponent } from './ping/ping.component';
import { BrowserLayoutComponent } from './layouts/browserLayout/browserLayout.component';
import { MobileLayoutComponent } from './layouts/mobileLayout/mobileLayout.component';

export const AppRootRoutes = [
  // MOBILE/LOGIN routes go here
  {
    path: '',
    component: MobileLayoutComponent,
    children: [
      { path: ROUTE_CONSTANTS.login.route, component: LoginComponent },
      { path: ROUTE_CONSTANTS.register.route, component: RegisterComponent },
      { path: ROUTE_CONSTANTS.ping.route, component: PingComponent },
      {
        path: ROUTE_CONSTANTS.track.route,
        component: TrackTabComponent,
        canActivate: [AuthGuard],
      },
      { path: '', component: HomeTabComponent, canActivate: [AuthGuard] },
    ],
  },

  // BROWSER routes go here
  {
    path: '',
    component: BrowserLayoutComponent,
    children: [
      {
        path: ROUTE_CONSTANTS.query.route,
        component: QueryTabComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTE_CONSTANTS.stats.route,
        component: StatsTabComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTE_CONSTANTS.help.route,
        component: HelpTabComponent,
        canActivate: [AuthGuard],
      },
      {
        path: ROUTE_CONSTANTS.nonmobile.route,
        component: NonMobileTabComponent,
        canActivate: [AuthGuard],
      },
      { path: '**', component: PageNotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(AppRootRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
