export enum IStatusId {
  Open = 1,
  Rejected = 2,
  Accepted = 3,
  Withdrawn = 4,
  Countered = 5,
  Expired = 6,
}

export enum IStatus {
  Open = 'Open',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  Withdrawn = 'Withdrawn',
  Countered = 'Countered',
  Expired = 'Expired',
}

export interface ICoordinates {
  latitude: number;
  longitude: number;
}

export interface ICategory {
  category_id: number;
  org_id: number;
  org_name: string;
  category_title: string;
  category_detail: string;
  updated: string | null;
}

export interface IRequest {
  request_id: number;
  category: string;
  request_text: string;
  lat: number;
  lon: number;
  offer_count: number;
  status: IStatus;
  requester: string;
  requester_id: number;
  created: string;
  expiration: string;
}

export interface IOffer {
  offer_id: number;
  counter_offer_id: number;
  request_id: number;
  requester_id: number;
  provider_id: number;
  requester_name: string;
  provider_name: string;
  offer_text: number;
  price: number;
  lat: number;
  lon: number;
  status_id: IStatusId;
  offer_status_title: IStatus;
  is_from_provider: number;
  fulfill_time: string;
  created: string;
  expiration: string;
}

export interface IAgreement {
  agreement_id: number;
  request_id: number;
  requester_id: number;
  provider_id: number;
  delivery_lat: number;
  delivery_lon: number;
  agreed_time: string;
  agreed_price: number;
  agreed_offer_text: string;
  agreement_status_id: IStatusId;
  agreement_status_reason: string;
  agree_status_title: string;
  requester_name: string;
  provider_name: string;
}
