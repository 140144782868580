import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ComponentWithSubscription } from '../../shared/component-with-subscriptions';
import { LoginResponse } from '../../shared/models/authentication';
import { ApplicationCacheService } from '../../shared/providers/application-cache.service';
import { AuthenticationService } from '../../shared/providers/authentication.service';
import { UserFeedbackService } from '../../shared/providers/user-feedback.service';

@Component({
  selector: 'track-tab',
  templateUrl: './track-tab.component.html',
  styleUrls: ['./track-tab.component.scss']
})
export class TrackTabComponent extends ComponentWithSubscription implements OnInit {

  lat = -23.8779431;
  lng = -49.8046873;
  zoom = 15;

  constructor(private activatedRoute: ActivatedRoute, private applicationCacheService: ApplicationCacheService,
              private authenticationService: AuthenticationService, private userFeedbackService: UserFeedbackService) {
    super();
  }

  ngOnInit() {

  }


}
