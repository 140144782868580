import { Injectable } from '@angular/core';
import { MaddashConfigurationOptions, MaddashUser } from '../models/user';
import { PORTAL_TYPE } from '../shared.constants';

@Injectable({
  providedIn: 'root'
})
export class ApplicationCacheService {
  configOptions: MaddashConfigurationOptions;
  portal = PORTAL_TYPE.HHA; // TTyree TEMP DEBUG - Hard code for initial prototype - FUTURE: make this dynamic

  currentPeriod = '';

  user: MaddashUser = null;

  get user_id() {
    return this.user && this.user.user_id;
  }

  setUser(user: MaddashUser) {
    this.user = user;
  }

  setConfigOptions(configOptions: MaddashConfigurationOptions) {
    this.configOptions = configOptions;
  }

}
