import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { ROUTE_CONSTANTS } from '../shared/shared.constants';
import { environment } from 'src/environments/environment';
import { ResponseContentType, HttpRequest } from '../api/http-request';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent
  extends ComponentWithSubscription
  implements OnInit
{
  @Output() registerStatus = new EventEmitter();

  message: string;
  returnUrl: string;
  accountTypes = [
    { name: 'Starter (Free)', value: 'starter' },
    { name: 'Professional', value: 'professional' },
    { name: 'Business', value: 'business' },
    { name: 'Entreprise', value: 'entreprise' },
  ];
  user: { [key: string]: string } = {
    entity: 'as-organization',
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    org_name: '',
    account_type: 'starter',
    notes: '',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpRequest
  ) {
    super();
  }

  ngOnInit() {
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams[ROUTE_CONSTANTS.returnUrlLabel];
  }

  get isOrganization(): boolean {
    return this.user.entity === 'as-organization';
  }

  /**
   * Get the data to send to the API after sanitisation.
   *
   * @returns Record<string, string>
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  get formattedData(): Record<string, string> {
    return {
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      phone: this.user.phone.substring(this.user.phone.length - 10),
      email: this.user.email,
      org_name: this.user.org_name,
      account_type: this.user.account_type,
    };
  }

  onSubmit(): void {
    // Get the URL.
    const urlRequest = environment.apiUrl + '/register';
    console.dir(this.formattedData);
    // Submit the information.
    this.http
      .post(urlRequest, this.formattedData, {
        responseType: ResponseContentType.json,
      })
      .subscribe(
        (data) => console.dir(data),
        (error) => console.log('error:', error)
      );
  }
}
