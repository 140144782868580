import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CurrentUserResponse } from '../shared/models/user';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class UserApi {
    constructor(private api: ApiService, protected http: HttpClient) { }

    getCurrent(): Observable<CurrentUserResponse> {
        return this.api.get('User', 'Current');
    }

    /**
    * Logs an event on the api
    * @param message the message to log
    */
    logEvent(message: string): Observable<boolean> {
        return this.api.post('User', 'Log', {
            message
        });
    }
}
