import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatListModule, MatNativeDateModule, MatProgressBarModule, MatRadioModule, MatSelectModule, MatSlideToggleModule } from '@angular/material';



@NgModule({
	imports: [
		CommonModule, FormsModule, ReactiveFormsModule
		// MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatListModule, MatNativeDateModule, MatProgressBarModule, MatRadioModule, MatSelectModule, MatSlideToggleModule,
	],
	declarations: [
	],
	exports: [
		CommonModule, FormsModule, ReactiveFormsModule,
		// MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatExpansionModule, MatFormFieldModule, MatInputModule, MatListModule, MatNativeDateModule, MatProgressBarModule, MatRadioModule, MatSelectModule, MatSlideToggleModule,
	]
})
export class MaddashComponentsModule { }
