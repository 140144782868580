import { IRequest, IOffer } from '../shared/shared.interfaces';
import { environment } from 'src/environments/environment';

export default class RequestService {
	private apiURL: string = environment.apiUrl;

	/**
	 * Get the offers from the API.
	 */
	async getOffers(requestId: number): Promise<IOffer[]> {
		return [];
	}
}
