import { MaddashUser } from './user';

export class LoginResponse {
    success: string;
    user_id: string;

    constructor(obj: any) {
        this.success = obj.success || '';
        this.user_id = obj.user_id || '';
    }
}

export class VerifyResponse {
    authenticated: boolean;
    user?: MaddashUser;
    token?: string;
    returnUrl?: string;

    constructor(obj: any) {
        this.authenticated = obj.authenticated;
        this.user = new MaddashUser(obj.user);
        this.authenticated = obj.authenticated;
        this.returnUrl = obj.returnUrl;
        if (obj.token) {
            this.token = obj.token;
        }
    }
}
