import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import 'hammerjs';
import { AppComponent } from './app.component';
import { BrowserLayoutComponent } from './layouts/browserLayout/browserLayout.component';
import { MobileLayoutComponent } from './layouts/mobileLayout/mobileLayout.component';
import { AppRoutingModule } from './app.routes';
import { LoginComponent } from './login/login.component';
import { PingComponent } from './ping/ping.component';
import { RegisterComponent } from './register/register.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LOCAL_STORAGE_KEY } from './shared/shared.constants';
import { SharedModule } from './shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { QueryTabComponent } from './main/queryTab/query-tab.component';
import { HomeTabComponent } from './main/homeTab/home-tab.component';
import { StatsTabComponent } from './main/statsTab/stats-tab.component';
import { NonMobileTabComponent } from './main/nonMobileTab/nonMobile-tab.component';
import { TrackTabComponent } from './main/trackTab/track-tab.component';
import { HelpTabComponent } from './main/helpTab/help-tab.component';
import { AgmCoreModule } from '@agm/core';
import { VerifyComponent } from './verify/verify.component';
import { PollService } from './poll/poll.service';
import { RefreshService } from './refresh/refresh.service';
import { MakeOfferComponent } from './modal/makeoffer/makeoffer.component';
import { MakeRequestComponent } from './modal/makerequest/makerequest.component';
import { PopupMsgComponent } from './modal/popupmsg/popupmsg.component';
import { ViewOfferComponent } from './modal/viewoffer/viewoffer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RequestListComponent } from './request-list/request-list.component';
import { RequestListItemComponent } from './request-list-item/request-list-item.component';
import RequestService from './services/request.service';


export function getAccessToken() {
	return localStorage.getItem(LOCAL_STORAGE_KEY.JWT);
}

@NgModule({
	imports: [
		HttpClientModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: getAccessToken,
				whitelistedDomains: [
					'localhost:5000',
					'localhost:5001',
					'localhost:44330',
					'spx.ieSimplified.com',
					'api.maddash2u.com',
					'mdashapi.herokuapp.com'
				]
			}
		}),
		BrowserAnimationsModule,
		AppRoutingModule,
		NgbModule,
		SharedModule.forRoot(),
		ReactiveFormsModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyAC-OKTQjR2KhljBOjGxwyzrsnOxWfhfD4'
		})
	],
	declarations: [
		AppComponent,
		BrowserLayoutComponent,
		MobileLayoutComponent,
		LoginComponent,
		PingComponent,
		RegisterComponent,
		VerifyComponent,
		QueryTabComponent,
		HomeTabComponent,
		StatsTabComponent,
		NonMobileTabComponent,
		TrackTabComponent,
		HelpTabComponent,
		PageNotFoundComponent,
		MakeOfferComponent,
		MakeRequestComponent,
		PopupMsgComponent,
		ViewOfferComponent,
		RequestListComponent,
		RequestListItemComponent
	],
	providers: [PollService, RefreshService, RequestService],
	bootstrap: [AppComponent]
})
export class AppModule { }
