import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserApi } from 'src/app/api/user.api';
import { AuthApi } from '../../api/auth.api';
import { ComponentWithSubscription } from '../component-with-subscriptions';
import { LoginResponse, VerifyResponse } from '../models/authentication';
import { CurrentUserResponse } from '../models/user';
import { LOCAL_STORAGE_KEY } from '../shared.constants';
import { ApplicationCacheService } from './application-cache.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends ComponentWithSubscription {
  jwtHelper = new JwtHelperService();

  backdoor_debugger_token = null;
  user_id = '';
  private _isLoggedIn: boolean=false;

  get decodedToken() {
    return this.jwtHelper.decodeToken(this.token);
  }

  get expirationDate() {
    return this.jwtHelper.getTokenExpirationDate(this.token);
  }

  get isExpired() {
    return this.jwtHelper.isTokenExpired(this.token);
  }

  get token() {
    const t = localStorage.getItem(LOCAL_STORAGE_KEY.JWT); // debugger
    return localStorage.getItem(LOCAL_STORAGE_KEY.JWT);
  }

  setLoggedIn() {
    this.isLoggedIn(true); // Get indicator if token is set - ignore return value
  }

  isLoggedIn(checkToken:boolean = false) {
    if (checkToken) {
      if (this.token) { 
        this._isLoggedIn = true;
        // FUTURE: TODO: Check if token has expired!
      } else {
        this._isLoggedIn = false;
      } 
    }
    return this._isLoggedIn;
  }

  /*
  backdoor_debugger_login() {
    localStorage.setItem(LOCAL_STORAGE_KEY.JWT, 'loggedin');
    this.backdoor_debugger_token = 'loggedin';
  }

  backdoor_debugger_logout() {
    localStorage.removeItem(LOCAL_STORAGE_KEY.JWT);
  }
*/

  get isPortalAdmin() {
    // Todo: there has to be a better way
    const user = this.appCache.user;
    return user.customerId === 1;
  }

  constructor(private authApi: AuthApi, private userApi: UserApi, private router: Router, private appCache: ApplicationCacheService) {
    super();
    this.setLoggedIn();
  }

  // LOGIN: Send email or phone number (already registerd) to server to cause an email/SMS temporary security token to be sent
  // Response back is a confirmation with a user_id
  login(phone_or_email) {
    const blah2 = this.authApi.login(phone_or_email);
    return blah2;
  }

  // VERIFY: Send user_id (from login) and temporary security token (entered by user from email/SMS message)
  verify(securitytoken) {
    return this.authApi.verify(this.user_id, securitytoken);
  }

  onLogin(authLogin: LoginResponse) {
    this.user_id = authLogin.user_id;
    this.setLoggedIn();
  }

  getUserId() {
    // Future: TODO: different approach?
    if (!this.user_id) {
      const jwt = this.decodedToken;
      this.user_id = jwt.user_id;
    }
    return this.user_id;
  }

  onVerify(authResponse: VerifyResponse) {
    if (!authResponse || !authResponse.authenticated) {
      return;
    }

    localStorage.setItem(LOCAL_STORAGE_KEY.JWT, authResponse.token);
  }

  logout() {
    this.clearToken();
    this.router.navigate(['login']);
  }

  clearToken() {
    localStorage.removeItem(LOCAL_STORAGE_KEY.JWT);
    this.setLoggedIn();  // Will indicate that we are no longer logged in.
  }

  async getCurrentUser(): Promise<CurrentUserResponse> {
    const results = await this.userApi.getCurrent().toPromise();
    const userResponse = new CurrentUserResponse(results);

    const { user: currentUser, configOptions, portals } = userResponse;
    this.appCache.setUser(currentUser);
    this.appCache.setConfigOptions(configOptions);

    return userResponse;
  }
}
