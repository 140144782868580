import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { ComponentWithSubscription } from '../../shared/component-with-subscriptions';
import { LoginResponse } from '../../shared/models/authentication';
import { ApplicationCacheService } from '../../shared/providers/application-cache.service';
import { AuthenticationService } from '../../shared/providers/authentication.service';
import { UserFeedbackService } from '../../shared/providers/user-feedback.service';
import { environment } from 'src/environments/environment';
import { HttpOptions, HttpRequest, ResponseContentType } from '../../api/http-request';
import { PopupMsgComponent } from 'src/app/modal/popupmsg/popupmsg.component';
import { Subscription } from 'rxjs';
import { PollService, pollToken } from 'src/app/poll/poll.service';

@Component({
  selector: 'query-tab',
  templateUrl: './query-tab.component.html',
  styleUrls: ['./query-tab.component.scss']
})
export class QueryTabComponent extends ComponentWithSubscription implements OnInit {

  input_p2;
  input_p3;
  categories = [];
  statuslist = [];
  requestList = [];
  newreq_expiration;
  newreq_lon;
  newreq_lat;
  newreq_category;
  newreq_request;
  avail_category;
  ReqSubmitStatus;

  geoPosition;
	geoPositionTime;
	geoCount = 0;
  
  categoriesList: any;
  notificationLog: any;
  pollPipe: Subscription;
  
  @ViewChild('popup', { static: false }) popupmodal:PopupMsgComponent;
  
  constructor(private activatedRoute: ActivatedRoute, private applicationCacheService: ApplicationCacheService,
              private authenticationService: AuthenticationService,
              private userFeedbackService: UserFeedbackService,
              private http: HttpRequest,
              private poll: PollService
              ) {
    super();
  }

  ngOnInit() {

    this.categoriesList = this.poll.categories; // get existing categories
    this.geoPosition = this.poll.geoPosition;
    this.geoPositionTime = this.poll.geoPositionTime;
    this.notificationLog = this.poll.notificationLog;

    // subscribe to get pipe of request/provider/user/categories stats --- NEW method...
    this.pollPipe = this.poll.pollPipe.subscribe(
      data => {
        const dany:any = data;
        // Categories
        if (dany.categories) {
          this.categoriesList = dany.categories;
        }
        if (dany.notifications) {
          this.notificationLog = dany.notifications;
        }
        if (dany.geoposition) {
          this.geoPosition = dany.geoposition;
          this.geoPositionTime = new Date();
          this.geoCount += 1;
        }
      }
    );

    // Request categories - only runs if needed
    this.poll.pollNow(pollToken.categories);
  }

  runMobileReport() {
    alert('Run mobile report');
  }

  runFullReport() {
    alert('Run full report');
  }

  GetCategories() {

    const urlRequest = environment.apiUrl + '/category';
    const urlParams = {
    };
    this.http.get(urlRequest, urlParams, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      this.categories = data ;
    },
     error => {
       console.log('error:', error);
     }
    );
  }

  GetStatusList() {

    const urlRequest = environment.apiUrl + '/status_list';
    const urlParams = {
    };
    this.http.get(urlRequest, urlParams, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      this.statuslist = data ;
    },
     error => {
       console.log('error:', error);
     }
    );
  }

  NewRequest() {

    const urlRequest = environment.apiUrl + '/request';
    const urlParams = {
      expiration: this.newreq_expiration,
      lon: this.newreq_lon,
      lat: this.newreq_lat,
      category_id: this.newreq_category,
      request_text: this.newreq_request
    };

    this.http.post(urlRequest, urlParams, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      this.ReqSubmitStatus = data ;
    },
     error => {
       console.log('error:', error);
     }
    );
  }

  GetRequestList() {

    const urlRequest = environment.apiUrl + '/request';
    const urlParams = {
    };

    this.http.get(urlRequest, urlParams, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      this.requestList = data ;
    },
     error => {
       console.log('error:', error);
     }
    );
  }

  SetLocation() {

    const urlRequest = environment.apiUrl + '/location';
    const urlParams = {
      lon: this.newreq_lon,
      lat: this.newreq_lat
    };

    this.http.put(urlRequest, urlParams, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      this.requestList = data ;
    },
     error => {
       console.log('error:', error);
     }
    );
  }

  SetAvailable() {
    if (!this.avail_category) {
      this.popupmodal.showWarning('Category required.');
      return;
    }
    const urlRequest = environment.apiUrl + '/available/' + this.avail_category;
    const urlParams = { };

    this.http.post(urlRequest, urlParams, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      this.requestList = data ;
    },
     error => {
       console.log('error:', error);
     }
    );
  }

  SetUnavailable() {
    if (!this.avail_category) {
      this.popupmodal.showWarning('Category required.');
      return;
    }
    const urlRequest = environment.apiUrl + '/available/' + this.avail_category;
    const urlParams = {};

    this.http.delete(urlRequest, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      this.requestList = data ;
    },
     error => {
       console.log('error:', error);
     }
    );
  }



  /** ================================================================ */

	geoRefresh() {
    this.poll.pollNow(pollToken.geoposition);
  }

  geoRefresh2() {
    this.poll.pollNow(pollToken.geoposition);
  }

  geoPushToForm() {
    if (!this.newreq_expiration) {
      const dt = new Date();
      dt.setDate(dt.getDate() + 1);
      const dtFull = dt.getFullYear() + '-' + this.zeroPad(dt.getMonth() + 1) + '-' + this.zeroPad(dt.getDate());
      this.newreq_expiration = dtFull;
    }
    this.newreq_lon = this.geoPosition.coords.longitude;
    this.newreq_lat = this.geoPosition.coords.latitude;
  }

  zeroPad(n) {
    const ret = '00' + n;
    return ret.substring(ret.length - 2);
  }

	getGeoPositionLong() {
		return this.geoPosition && this.geoPosition.coords && this.geoPosition.coords.longitude;
	}
	getGeoPositionLat() {
		return this.geoPosition && this.geoPosition.coords && this.geoPosition.coords.latitude;
  }


}
