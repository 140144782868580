import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentWithSubscription } from '../../shared/component-with-subscriptions';
import { ApplicationCacheService } from '../../shared/providers/application-cache.service';
import { AuthenticationService } from '../../shared/providers/authentication.service';
import { UserFeedbackService } from '../../shared/providers/user-feedback.service';
import { environment } from 'src/environments/environment';
import { HttpRequest, ResponseContentType } from '../../api/http-request';
import { PollService, pollToken } from 'src/app/poll/poll.service';
import { Subscription } from 'rxjs';
import { RefreshService } from 'src/app/refresh/refresh.service';
import { MakeOfferComponent } from 'src/app/modal/makeoffer/makeoffer.component';
import { HelperService } from 'src/app/services/helper.service';
import { MakeRequestComponent } from 'src/app/modal/makerequest/makerequest.component';
import { ViewOfferComponent } from 'src/app/modal/viewoffer/viewoffer.component';
import { PopupMsgComponent } from 'src/app/modal/popupmsg/popupmsg.component';

@Component({
  selector: 'nonMobile-tab',
  templateUrl: './nonMobile-tab.component.html',
  styleUrls: ['./nonMobile-tab.component.scss']
})
export class NonMobileTabComponent extends ComponentWithSubscription implements OnInit, OnDestroy {

  input_p2;
  input_p3;
  categoriesList: any;
  requestList: any;
  requestListOffers = {};
  requestProviderList: any;
  userStatsData: any;
  // requestPipe: Subscription;
  // providerPipe: Subscription;
  // userStatsPipe: Subscription;
  pollPipe: Subscription;
  refreshSubscription: Subscription;
  newreq_expiration;
  newreq_lon;
  newreq_lat;
  newreq_category;
  newreq_request;
  ReqSubmitStatus;
  requestUserStats;
  requestUserStats_view;

  geoPosition;
	geoPositionTime;
	geoCount = 0;

  faArrowRight = ['fa', 'fa-caret-square-o-right'];
  faArrowDown = ['fa', 'fa-caret-square-o-down'];

  constructor(private activatedRoute: ActivatedRoute, private applicationCacheService: ApplicationCacheService,
              private authenticationService: AuthenticationService,
              private userFeedbackService: UserFeedbackService,
              private http: HttpRequest,
              private poll: PollService,
              private refreshService: RefreshService,
              private helper: HelperService) {
    super();
  }

  @ViewChild('makeoffer1', { static: false }) makeoffermodal: MakeOfferComponent;
  @ViewChild('viewoffermodal', { static: false }) viewoffermodal: ViewOfferComponent;
  @ViewChild('makerequest1', { static: false }) makerequestmodal: MakeRequestComponent;
  @ViewChild('popup', { static: false }) popupmodal:PopupMsgComponent;

  ngOnInit() {

    // this.geoInit();
    this.requestList = this.poll.requestData; // get existing request list
    //debugger
    this.requestProviderList = this.poll.providerData; // get existing provider list
    this.setUserStatsData(this.poll.userStatsData);  // get existing user stats
    this.categoriesList = this.poll.categories; // get existing categories
    this.geoPosition = this.poll.geoPosition;
    this.geoPositionTime = this.poll.geoPositionTime;

    // We should not have to setup requestListOffers yet because the screen always opens with all rows closed

    // subscribe to get pipe of request/provider/user/categories stats --- NEW method...
    this.pollPipe = this.poll.pollPipe.subscribe(
      data => {
        // Requests
        const dany:any = data;
        if (dany.requests) {
          this.requestList = dany.requests;
          this.reloadOffers(false); // other offers to my Requests
        }
        // Providers
        if (dany.providers) {
          this.requestProviderList = dany.providers;
          debugger;
          this.reloadOffers(true); // myOffers to other providers
        }
        // UserStats
        if (dany.userstats) {
          this.setUserStatsData(dany.userstats);
        }
        // Categories
        if (dany.categories) {
          this.categoriesList = dany.categories;
        }
        // geoPosition
        if (dany.geoposition) {
          this.geoPosition = dany.geoposition;
          this.geoPositionTime = new Date();
        }
      }
    );

    this.refreshSubscription = this.refreshService.refreshPipe.subscribe(
      () => {
        this.RefreshAll();
      }
    );

    // Request categories - only runs if needed
    this.poll.pollNow(pollToken.categories);

  }

  ngOnDestroy() {
    // this.requestPipe.unsubscribe();
    // this.providerPipe.unsubscribe();
    // this.userStatsPipe.unsubscribe();
    this.pollPipe.unsubscribe();
  }

  runMobileReport() {
    alert('Run mobile report');
  }

  runFullReport() {
    alert('Run full report');
  }


  RefreshAll() {
    this.poll.pollNow(pollToken.reload);
    // Show the world we are doing something...
    this.popupmodal.showMessage('Refresh','Processing...');
    setTimeout(()=>{ this.popupmodal.closeDialog(); },400);
  }

  setUserStatsData(data) {
    this.userStatsData = data;
    this.requestUserStats_view = JSON.stringify(data);
  }

  makeRequest() {
    const request_initialize = {
      expiration: this.helper.currentTime(24)
    };
    this.makerequestmodal.showDialog(request_initialize);
  }

  MakeOffer(req) {
    const user = this.authenticationService.getUserId();
    const tz = new Date().getTimezoneOffset();
    const offer_initialize = {
      request_id: req.request_id,
      provider_id: user,
      requester_id: req.requester_id,
      expiration: this.helper.currentTime(24),
      fulfill_time: this.helper.currentTime(1),
      timezone_offset: tz
    };
    this.makeoffermodal.showDialog(offer_initialize);
  }

  ViewOffer(offer) {
    const user = this.authenticationService.getUserId();
    const tz = new Date().getTimezoneOffset();
    const offer_initialize = {
      provider_id: user,
      timezone_offset: tz,
      ... offer
    };
    this.viewoffermodal.showDialog(offer_initialize);
  }

  /** ================================================================ */

	geoRefresh() {
    this.poll.pollNow(pollToken.geoposition);
	}

	getGeoPositionLong() {
		return this.geoPosition && this.geoPosition.coords && this.geoPosition.coords.longitude;
	}
	getGeoPositionLat() {
		return this.geoPosition && this.geoPosition.coords && this.geoPosition.coords.latitude;
  }

  toggleRequestRow(request_id, myOffers) {
    const reqId = this.createOfferRecord(request_id, myOffers);
    // We already have the list of offers!
    this.requestListOffers[reqId].show = !this.requestListOffers[reqId].show;
    // We are done if we are closing the row
    // else refresh the list if we are opening it... (ie. continue)
    if (this.requestListOffers[reqId].show) {
      // need to get/refresh data
      this.getOffers(request_id,myOffers);
    }
  }

  createReqId(request_id) {
    return 'r' + request_id;
  }

  createOfferRecord(request_id, myOffer) {
    const reqId = this.createReqId(request_id);
    if (!this.requestListOffers[reqId]) {
      this.requestListOffers[reqId] = {};
      this.requestListOffers[reqId].request_id = request_id;
      this.requestListOffers[reqId].show = false;
      this.requestListOffers[reqId].myOffer = myOffer;
      this.requestListOffers[reqId].data = [];
    }
    return reqId;
  }

  reloadOffers(myOffers) {
    for (let key in this.requestListOffers) {
        const oneOffer = this.requestListOffers[key];
        if (oneOffer.show && oneOffer.myOffer==myOffers) {
          this.getOffers(oneOffer.request_id,oneOffer.myOffer);
        }
       }
  }

  getOffers(request_id,myOffer) {
    if (myOffer) {
      this.getMyOffers(request_id);  // myOffers to other providers requests
    } else {
      this.getRequestOffers(request_id);  // other offers to my Requests
    }
  }

  // This function expects offer record to have already been created - createOfferRecord()
  getRequestOffers(request_id) {
    const reqId = this.createReqId(request_id);

    const urlRequest = environment.apiUrl + '/offer/request/' + request_id;
    const urlParams = {
    };

    this.http.get(urlRequest, urlParams, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      this.requestListOffers[reqId].data = data ;
    },
     error => {
       console.log('error:', error);
     }
    );
  }

  getMyOffers(request_id) {
    const reqId = this.createReqId(request_id);

    const urlRequest = environment.apiUrl + '/offer';
    const urlParams = {
    };

    this.http.get(urlRequest, urlParams, {responseType: ResponseContentType.json})
    .subscribe((data) => {
      const tmpList = this.groupBy(data, 'request_id');
      for (const [key, offers] of Object.entries(tmpList)) {
          const thisId = this.createOfferRecord(key,true);
          this.requestListOffers[thisId].data = offers;
      }
    },
     error => {
       console.log('error:', error);
     }
    );
  }

  groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  getRequestIconClass(request_id) {
    const reqId = 'r' + request_id;
    const rowClasses = {
        fa: true
    };
    if (this.requestListOffers[reqId] && this.requestListOffers[reqId].show) {
      rowClasses['fa-caret-square-o-down'] = true;
      return rowClasses;
    }
    rowClasses['fa-caret-square-o-right'] = true;
    return rowClasses;

  }

}
