import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../shared/providers/authentication.service';
import { ROUTE_CONSTANTS } from '../../shared/shared.constants';
import { Router } from '@angular/router';
import { RefreshService } from '../../refresh/refresh.service';

@Component({
  selector: 'mobilelayout-component',
  templateUrl: './mobileLayout.component.html',
  styleUrls: ['./mobileLayout.component.scss'],
})
export class MobileLayoutComponent {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private refreshService: RefreshService
  ) {}
  // @ViewChild(NotificationsComponent, { static: true }) notifications: NotificationsComponent;

  get authenticated() {
    const d =
      this.authenticationService.token && !this.authenticationService.isExpired; // debugger
    return (
      this.authenticationService.token && !this.authenticationService.isExpired
    );
  }

  /**
   * Get the current year from today's date.
   *
   * @returns number The year as an integer
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  goToDetail(e, goToRoute) {
    e.preventDefault();
    this.router.navigate([goToRoute]);
  }

  loginClick(e) {
    e.preventDefault();
    this.router.navigate([ROUTE_CONSTANTS.login.route]);
  }

  logoutClick(e) {
    e.preventDefault();
    // this.authenticationService.backdoor_debugger_logout();
    this.authenticationService.logout();
    this.router.navigate([ROUTE_CONSTANTS.login.route]);
  }

  goRefresh() {
    // call singleton service - each page must subscribe to the refresh subscription
    this.refreshService.refreshNow();
  }
}
