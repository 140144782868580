import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DefaultUrlSerializer } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class HelperService {

	constructor() { }

	currentDate(offsetDays) {
		const dt = new Date();
		let offset = 0;
		if (offsetDays) { offset = offsetDays; }
		dt.setDate(dt.getDate() + offset);
		const dtFull = dt.getFullYear() + '-' + this.zeroPad(dt.getMonth() + 1) + '-' + this.zeroPad(dt.getDate());
		return dtFull;
	}

	currentTime(offsetHours) {
		const dt = new Date();
		let offset = 0;
		if (offsetHours) { offset = offsetHours; }
		dt.setHours(dt.getHours() + offset);
		const dtFull = dt.getFullYear() + '-' + this.zeroPad(dt.getMonth() + 1) + '-' + this.zeroPad(dt.getDate())
			+ ' ' + this.zeroPad(dt.getHours()) + ':' + this.zeroPad(dt.getMinutes()) + ':' + this.zeroPad(dt.getSeconds());
		return dtFull;
	}

	zeroPad(n) {
		const ret = '00' + n;
		return ret.substring(ret.length - 2);
	}

}
