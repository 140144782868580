import { Component, ViewChild, TemplateRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequest, ResponseContentType } from '../../api/http-request';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PollService, pollToken } from 'src/app/poll/poll.service';
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'makeoffer-modal',
  templateUrl: './makeoffer.component.html',
  styleUrls: ['./makeoffer.component.scss'],
})
export class MakeOfferComponent {
  // tslint:disable-next-line: variable-name
  @ViewChild('modal_1', { static: false }) modal_1: TemplateRef<any>;
  // @Input() offerInit: any;

  offerForm: any = {};
  SubmitOfferStatus: any;
  modalReference: any;

  constructor(
    private http: HttpRequest,
    private modalService: NgbModal,
    private poll: PollService
  ) {}

  // Min date for expiration column.
  get minExpiration(): string {
    return new Intl.DateTimeFormat().format(new Date());
  }

  async showDialog(offerInit: any) {
    if (offerInit) {
      this.offerForm = {
        ...offerInit,
        ...{
          lat: offerInit.coordinates.latitude,
          lon: offerInit.coordinates.longitude,
        },
      };
    }

    const view = this.modal_1; // .createEmbeddedView(null);
    this.modalReference = this.modalService.open(view, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true,
    });
  }

  closeDialog() {
    if (this.modalReference) {
      this.modalReference.close();
    }
  }

  /**
   * The API required a special date format,
   * this method performs the formatting.
   *
   * @param date Date to format
   *
   * @returns string
   * @author Brian K. Kiragu <bkariuki@hotmail.com>
   */
  formatDate = (date: Date): string => {
    // Add leading zeros when numbers are less than or equal to 9.
    const addLeadingZeros = (n: number) => (n <= 9 ? `0${n}` : n);

    const year = date.getUTCFullYear();
    const month = addLeadingZeros(date.getUTCMonth() + 1);
    const day = addLeadingZeros(date.getUTCDate());

    const hour = addLeadingZeros(date.getUTCHours());
    const minute = addLeadingZeros(date.getUTCMinutes());
    const second = addLeadingZeros(date.getUTCSeconds());

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  submitOffer() {
    // Get the request URL.
    const urlRequest = environment.apiUrl + '/offer';

    // Modify the fulfil date.
    const fulfill_time = this.formatDate(new Date(this.offerForm.fulfill_time));

    // Modify the data with the formatted fulfil_time to submit.
    this.offerForm = { ...this.offerForm, ...{ fulfill_time } };

    // Launch the request.
    this.http
      .post(urlRequest, this.offerForm, {
        responseType: ResponseContentType.json,
      })
      .subscribe(
        (data) => {
          this.SubmitOfferStatus = data;
          // Refresh the visible list.
          this.poll.pollDelay(pollToken.providers);
          this.closeDialog();
        },
        (error) => {
          console.log('error:', error);
          alert('failed to send offer');
        }
      );
  }
}
