import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PollService } from './poll/poll.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  startPoll: Subscription;
  startSockets;

  constructor(private router: Router, private poll: PollService
              ) { }
              
  ngOnInit() {
    this.startPoll = this.poll.ticPipe.subscribe();  // This starts the polling and keeps it going.
  }

  ngOnDestroy() {
    this.startPoll.unsubscribe();
  }

}
