import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../shared/providers/authentication.service';
import { ROUTE_CONSTANTS } from '../../shared/shared.constants';
import { Router } from '@angular/router';
import { RefreshService } from '../../refresh/refresh.service';


@Component({
  selector: 'browserlayout-component',
  templateUrl: './browserLayout.component.html',
  styleUrls: ['./browserLayout.component.scss']
})
export class BrowserLayoutComponent implements OnInit, OnDestroy {

  constructor(private authenticationService: AuthenticationService,
              private router: Router, 
              private refreshService: RefreshService,
              ) { }
  // @ViewChild(NotificationsComponent, { static: true }) notifications: NotificationsComponent;

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  get authenticated() {
    const d = this.authenticationService.token && !this.authenticationService.isExpired; // debugger
    return this.authenticationService.token && !this.authenticationService.isExpired;
  }

  goToDetail(e, goToRoute) {
    e.preventDefault();
    this.router.navigate([goToRoute]);
  }

  loginClick(e) {
    e.preventDefault();
    this.router.navigate([ROUTE_CONSTANTS.login.route]);
  }

  logoutClick(e) {
    e.preventDefault();
    // this.authenticationService.backdoor_debugger_logout();
    this.authenticationService.logout();
    this.router.navigate([ROUTE_CONSTANTS.login.route]);
  }

  goRefresh() {
    // call singleton service - each page must subscribe to the refresh subscription
    this.refreshService.refreshNow();
  }
}
