import { Component, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequest, ResponseContentType } from '../../api/http-request';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "popupmsg-modal",
  templateUrl: './popupmsg.component.html',
  styleUrls: ['./popupmsg.component.scss']
})
export class PopupMsgComponent implements OnInit {
  @ViewChild('modal_req', { static: false }) modal_req: TemplateRef<any>;

  myMessage: any = {};
  SubmitOfferStatus;
  modalReference;

  constructor(private http: HttpRequest, private modalService: NgbModal) { }

  ngOnInit() {
  }

  showDialog(msg_initialize) {
    if (msg_initialize) {
      this.myMessage = { ... msg_initialize };
    }

    const view = this.modal_req; // .createEmbeddedView(null);
    this.modalReference = this.modalService.open(view, {
      backdrop: 'static',
      size: 'lg',
      keyboard: false,
      centered: true
    });
  }

  showWarning(message) {
    const msg = {
      title: "Warning",
      message: message
    };
    this.showDialog(msg);
  }

  showMessage(title,message) {
    const msg = {
      title: title,
      message: message
    };
    this.showDialog(msg);
  }

  closeDialog() {
    this.myMessage = {};
    if (this.modalReference) {
      this.modalReference.close();
    }
  }

}
