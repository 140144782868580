import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { VerifyResponse } from '../shared/models/authentication';
import { AuthenticationService } from '../shared/providers/authentication.service';
import { UserFeedbackService } from '../shared/providers/user-feedback.service';
import { ROUTE_CONSTANTS } from '../shared/shared.constants';
import { HttpRequest, ResponseContentType } from '../api/http-request';
import { environment } from '../../environments/environment';
import { MaddashUser, UserAccess } from '../shared/models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent
  extends ComponentWithSubscription
  implements OnInit
{
  loginid: any;
  passcode: string;
  message: string; // login error message
  responseData: { success?: any; user_id: any };
  verifyData: any;
  modeVerify = false;

  returnUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userFeedbackService: UserFeedbackService,
    private http: HttpRequest,
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit() {
    this.returnUrl =
      this.activatedRoute.snapshot.queryParams[ROUTE_CONSTANTS.returnUrlLabel];
  }

  login() {
    this.passcode = '';
    this.message = '';
    if (!this.loginid) {
      this.message = 'Phone or email is required';
      return;
    }

    this.userFeedbackService.startSpinner();

    const urlRequest = environment.apiUrl + '/token/request';

    // this.http.post(
    //   'https://mdashapi.herokuapp.com/api/token/request',
    //   { phone_or_email: this.loginid }, { responseType: ResponseContentType.json }
    // )
    this.http
      .post(
        urlRequest,
        { phone_or_email: this.loginid },
        { responseType: ResponseContentType.json }
      )
      .subscribe(
        (data) => {
          this.responseData = {
            success: data.success,
            user_id: data.user_id,
          };
          this.modeVerify = true;
          this.setFocus('#passcode');
        },
        (error) => {
          console.log('error:', error);
        }
      );
    /*
    this.authenticationService.login(this.loginid)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        finalize(() => this.userFeedbackService.stopSpinner())
      )
      .subscribe(
        async (obj: any) => {
          // Check if token was succefully sent to user phone/email
          if (!obj.success) {
            this.onInvalidLogin();
            return;
          }

          // Successful: Token Sent: Load user object and navigate to Login Menu
          this.authenticationService.onLogin(obj);
          // await this.authenticationService.getCurrentUser();

          // this.navigationService.navigateToVerify();
          this.router.navigate([ROUTE_CONSTANTS.verify.route]);

        },
        x => {
          this.loginid = '';
          this.onInvalidLogin();
          this.userFeedbackService.stopSpinner();
        });
*/

    // Temporary Login - DEBUGGER - FUTURE TODO - complete above API login
    // if (this.username == 'demo' && this.password == 'norbert') {
    //       this.authenticationService.backdoor_debugger_login();
    //       this.router.navigate([ROUTE_CONSTANTS.query.route]);
    //    }
  }

  onInvalidLogin() {
    this.message =
      'Login request failed. Please try again or try a different phone/email. If you are unable to login, you may need to register as a new user.';
  }

  clickVerify() {
    alert('verify!');
    const urlRequest = environment.apiUrl + '/token/verify';

    // this.http.post(
    //   'https://mdashapi.herokuapp.com/api/token/request',
    //   { phone_or_email: this.loginid }, { responseType: ResponseContentType.json }
    // )
    this.http
      .patch(
        urlRequest,
        {
          passcode: this.passcode,
          user_id: this.responseData.user_id,
        },
        {
          responseType: ResponseContentType.json,
        }
      )
      .subscribe(
        (data) => {
          // this.verifyData = {
          // 	success: data.success,
          // 	user_id: data.user_id
          // };

          const newUser = new MaddashUser({
            user_id: this.responseData.user_id,
            username: 'username',
            name: 'name',
            email: 'email',
            status: 'status',
            title: 'title',
            customerId: 7,
            failedLoginAttemptCount: 'failedLoginAttemptCount',
            expirationDate: 'obj.expirationDate',
            lastPasswordChangeDate: 'lastPasswordChangeDate',
            userAccess: new UserAccess(''),
          });

          const verifyResponse = new VerifyResponse({
            authenticated: true,
            user: this.responseData.user_id,
            token: data.jwt,
          });
          this.authenticationService.onVerify(verifyResponse);

          // After login, goto Query page
          this.router.navigate([ROUTE_CONSTANTS.query.route]);
        },
        (error) => {
          console.log('error:', error);
        }
      );
  }

  clickBack() {
    alert('back!');
    this.modeVerify = false;
    this.setFocus('#loginid');
  }

  setFocus(selector: string) {
    setTimeout(() => {
      const e = this.renderer.selectRootElement(selector);
      e.focus();
    }, 0);
  }
}
