import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
export class RefreshService implements OnDestroy {

  public refreshPipe = new Subject();

  constructor() {}

  ngOnDestroy() {}

  public refreshNow() {
    this.refreshPipe.next();
  }

}
