import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentWithSubscription } from '../shared/component-with-subscriptions';
import { HttpOptions, HttpRequest, ResponseContentType } from '../api/http-request';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-ping',
  templateUrl: './ping.component.html',
  styleUrls: ['./ping.component.scss']
})
export class PingComponent extends ComponentWithSubscription implements OnInit {
  message; // ping error/success message
  responseData;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
              private http: HttpRequest) {
    super();
  }

  ngOnInit() {
  }

  ping() {
    this.message = 'sending ping...';
    const urlRequest = environment.apiUrl + '/ping';

    this.http.get(urlRequest)
    .subscribe((data) => {
      if (data && data.status && data.status === 'OK') {
        this.message = 'Ping was successful<br>Server time: ' + data.time;
      } else {
        this.message = 'FAILED: Ping did not return a success message.';
      }
    },
     error => {
       this.message = 'ERROR: ' + (error && error.message);
       console.log('error:', error);
     }
    );
  }

}
