import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginResponse, VerifyResponse } from '../shared/models/authentication';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AuthApi {
	constructor(private api: ApiService, protected http: HttpClient) {}
	login(phone_or_email): Observable<LoginResponse> {
		const blah = this.api.post('token', 'request', { // future: chenge second parameter to 'request'
			phone_or_email
		});
		return blah;
	}

	verify(user_id, passcode): Observable<VerifyResponse> {
		return this.api.patch('token', 'verify', { // future: chenge second parameter to 'verify'
			user_id,
			passcode
		});
	}
}
